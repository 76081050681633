/* style2.css */
.bar {
    padding: 0 8px;
}

.bar2 {
    padding: 0 8px;
}

.bar::before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 5px;
    width: 1px;
    height: 8px;
    background-color: #e7ebee;
    padding: 0 0px;
}

/* table */
.tbl_box {
    padding: 12px 20px 0px 20px;
    margin-top: 0;
    /*margin-bottom: 24px;*/
}

.tbl {
    padding: 0;
    margin-top: 0;
    margin-bottom: 24px;
    border: 1px solid #e7ebee;
    border-radius: 12px;
}

.tbl table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 12px;
}

.tbl caption {
    text-align: left;
    display: none;
}

.tbl table th,
.tbl table td {
    height: 42px;
    padding: 10px;
    color: #1a1d1d;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    box-sizing: border-box;
    border-right: 1px solid #e7ebee;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #e7ebee;
}

.tbl table th:last-child,
.tbl table td:last-child {
    border-right: none;
}

.tbl table th {
    background-color: #f9fafb;
    text-align: center;
    word-break: keep-all;
    word-wrap: break-word;
    color: #3b4044;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.tbl table td {
    background-color: transparent;
    font-weight: normal;
    color: #525960;
    padding: 13px 10px;
}

.tbl table th.left,
.tbl table td.left {
    text-align: left !important;
}

.tbl table th.right,
.tbl table td.right {
    text-align: right !important;
}

.tbl table tr:last-child td {
    border-bottom: none;
}

.tbl table th .required {
    color: #eb008b;
}

.tbl table th.line,
.tbl table td.line {
    border-left: 1px solid #e7ebee !important;
}

.tbl table tfoot td {
    padding: 12px 10px;
    background-color: #f2f3f4;
}

.tbl table td .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;
}

.tbl.tbl-border table thead th,
.tbl.tbl-border table tbody td {
    background: #fff;
}

.scroll-tbl {
    overflow-x: auto;
    overflow-y: hidden;
}

.scroll-tbl::-webkit-scrollbar {
    width: 30%;
    height: 4px;
}

.scroll-tbl::-webkit-scrollbar-thumb {
    background: #aeaeb2;
    border-radius: 2px;
}

.scroll-tbl::-webkit-scrollbar-track {
    background: #fff;
}

/* 투명 table */
.tbl_box.clear {
    padding: 12px 20px;
    margin-top: 0;
    margin-bottom: 24px;
}

.tbl.clear {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    border-radius: 12px;
}

.tbl.clear table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 12px;
}

.tbl.clear caption {
    text-align: left;
    display: none;
}

.tbl.clear table th,
.tbl.clear table td {
    height: 42px;
    padding: 10px;
    color: #3b4044;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    box-sizing: border-box;
    border-right: none;
    text-align: left;
    vertical-align: middle;
    border-bottom: none;
}

.tbl.clear table th:last-child,
.tbl.clear table td:last-child {
    border-right: none;
}

.tbl.clear table th {
    background-color: #f9fafb;
    text-align: center;
    word-break: keep-all;
    word-wrap: break-word;
    color: #3b4044;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.tbl.clear table td {
    background-color: transparent;
    font-weight: normal;
    color: #3b4044;
    padding: 16px 6px;
}

.tbl.clear table td:first-child {
    padding-left: 0;
}

.tbl.clear table td:last-child {
    padding-right: 0;
}

.tbl.clear table th.left,
.tbl.clear table td.left {
    text-align: left !important;
}

.tbl.clear table th.right,
.tbl.clear table td.right {
    text-align: right !important;
}

.tbl.clear table tr:last-child td {
    border-bottom: none;
}

.tbl.clear table th .required {
    color: #eb008b;
}

.tbl.clear table th.line,
.tbl.clear table td.line {
    border-left: 1px solid #e7ebee !important;
}

.tbl.clear table tfoot td {
    padding: 12px 10px;
    background-color: #f2f3f4;
}

.tbl.clear table td .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;
}

.tbl.clear.tbl-border table thead th,
.tbl.clear.tbl-border table tbody td {
    background: #fff;
}

/* 말풍선 */
.noti_block {
    position: relative;
}

.noti {
    position: absolute;
    left: 20px;
    top: 0;
    width: auto;
    line-height: 1rem;
    color: #fff;
    font-size: 10px;
    border-radius: 12px;
    padding: 3px 8px 4px 8px;
    background-image: linear-gradient(90deg, #f6257f 0%, #ae0abc 100%);
    box-sizing: border-box;
    max-width: 70%;
}

.noti:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #e61f8e;
    border-bottom: 0;
    margin-left: -4px;
    margin-bottom: -4px;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #f6257f 0%, #ae0abc 100%);
    border-image-slice: 1;
    background-origin: border-box;
    background-clip: content-box, border-box;
    padding: 0;
}

/* Snackbar */
.snackbar {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    padding: 12px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    background: #3b4044;
    border-radius: 8px;
    position: fixed;
    bottom: 43px;
    left: 20px;
    right: 20px;
}


/* 이벤트 배너 */
ol.marker li::marker {
    color: #66707a;
}

ul.marker li::marker {
    color: #66707a;
    font-size: 10px;
}

ul.none {
    list-style-type: none;
}

ul.none li::marker {
    padding-left: 0;
}

/* slider 튜토리얼 */
.slick-dots li {
    width: 6px !important;
    margin: 0 6px !important;
}

.slick-dots li button:before {
    font-size: 6px !important;
    opacity: 1 !important;
    color: #c8c8c8 !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #66707a !important;
}

.slick-dots li button {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    opacity: 0.4 !important;
    cursor: pointer !important;
    margin-top: 7px !important;
    padding: 0 !important;
}

.slick-dots li.slick-active button {
    background-color: #66707a !important;
    width: 18px !important;
    height: 6px !important;
    border-radius: 9px !important;
    opacity: 1 !important;
    cursor: pointer !important;
}

/* 혜택 메인 image */
.image_box {
    position: relative;
}

.hover_arrow {
    position: relative;
    padding-right: 20px;
    transition: padding-right 0.3s ease;
}

.hover_arrow::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -6px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background-image: url(../images/etc/event_focus_arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
}

.hover_arrow:hover::before {
    opacity: 1;
    padding-right: 20px;
}

.hover_arrow img:hover {
    border: 2px solid #d4d5dc;
    border-radius: 16%;
    box-sizing: border-box;
}

.hover_arrow img {
    width: 100%;
}

.hover_arrow.active::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background-image: url("../images/etc/event_focus_arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.hover_arrow.active:hover::before {
    opacity: 1;
    padding-right: 20px;
}

.hover_arrow.active img {
    border: 2px solid #d4d5dc;
    border-radius: 16%;
    box-sizing: border-box;
}

/* 임시 추가 (BottomSheet) */
/* .dGwuQI {
  height: auto !important;
  overflow: hidden !important;
  padding: 0 !important;
  min-height: 56px;
} */
/* 디자인시스템 요청으로 지움 240725 */
.cazpRm {
    padding: 0 0 1.75rem 0 !important;
}

/* 임시 추가 (BS_EventPageDetails BottomSheet 닫기 컬러 지정) */
/* .MGTCW {
  color: #181a1b !important;
} */
/* 디자인시스템 요청으로 지움 240725 */

/* 임시 추가 (Snackbar) */
/* .RUIKZ {
  bottom: 30px !important;
} */
/* .flZiga {
  min-height: 42px !important;
} */
/* 디자인시스템 적용시점이라 삭제 */

/* .VnpSO {
  display: none !important;
} */

/* 임시 추가 (List) */
.fKxHMn {
    /* border-bottom: none !important; */
}

/* 디자인시스템 적용시점이라 삭제 */

/* 임시 추가 (Carousel) HomeBenefit 외 */
.lmzCXq {
    overflow: hidden;
}

/* 임시 추가 (롤오버 image) HomeBenefit */
.kKzMEt {
    /* height: unset !important; */
}

.TOmvL {
    /* overflow: unset !important; */
}

.kKzMEt {
    /* position: relative; */
}

/* 임시 추가 (List) - UMembershipTermUnited7  오른 아이콘 없애기 */
/* .djaFnr .chevron_right_small_line {
  display: none;
} */

/* .joycvo img {
  border-radius: 12px;
  box-sizing: border-box;
  background: transparent;
}
.joycvo img:hover {
  border: 2px solid #d4d5dc;
  border-radius: 15%;
  box-sizing: border-box;
  background: red;
}
.joycvo img:hover::after {
  content: "";
  top: 50%;
  left: 1em;
  border: 1em solid transparent;
  border-right: 1em solid #fea0b2;
  z-index: 0;
} */

/* .joycvo img {
  border-radius: 12px;
  box-sizing: border-box;
  background: transparent;
  -webkit-mask-image: url("../images/main/benefit/thumb_01.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-image: url("../images/main/benefit/thumb_01.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  width: 100%;
  height: 100%;
} */
