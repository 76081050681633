.calendar {
    position: relative;
    display: flex;
    gap: 6px;
    overflow: auto;
    padding: 20px 0 18px 20px;
}

/*.calendar::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 0;*/
/*  width: 16px;*/
/*  height: 94px;*/
/*  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);*/
/*  z-index: 2;*/
/*}*/
.data_box {
    width: 56px;
    height: 56px;
    background-color: #f3f5f6;
    border-radius: 12px;
    position: relative;
    color: #7f8a94;
    min-width: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data_box.event {
    background-color: #e6007e;
    color: #fff;
}

.data_box.event::before {
    content: "";
    position: absolute;
    bottom: -4px; /* 위치를 조절합니다 */
    left: 19px; /* 위치를 조절합니다 */
    transform: translateX(-50%);
    width: 19px;
    height: 19px;
    background-color: #e6007e;
    border-radius: 2px;
    transform: rotate(45deg);
}

.data_box.end {
    background-color: #f3f5f6;
    color: #dce0e5;
}

.data_box.end span.data {
    color: #dce0e5;
}

.data_box span {
    display: block;
    text-align: center;
}

.data_box span.data {
    color: #181a1b;
}

.data_box.event span.data {
    color: #fff;
}

/* section17 - SectionBenefitGraph */
.benefit_graph {
    padding-top: 12px;
}

.benefit_graph .header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benefit_graph .header .arrowborder {
    border: 1px solid #e7ebee;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefit_graph .header .center {
    display: flex;
    align-items: center;
}

.benefit_graph .body {
    padding: 24px 20px;
}

.benefit_graph .text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.benefit_graph .graph_box {
    padding: 43px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 24px 0 0;
}

.benefit_graph .bar_base {
    display: flex;
    width: 100%;
    background-color: #f3f5f6;
    border-radius: 40px;
    height: 20px;
    position: relative;
}

.benefit_graph .bar_base .bar-fill {
    background: linear-gradient(to right, #F6257F, #AE0ABC);
    height: 20px;
    border-radius: 40px;
    width: var(--bar-percent, 0%);
    position: relative;
}

.benefit_graph .bar_base .label {
    width: 1px;
    height: 36px;
    border-left: 1px dashed #e6007e;
    box-sizing: border-box;
    margin-top: -17px;
}

.benefit_graph .use_amount {
    position: absolute;
    left: var(--use-amount-left, 0%);
    top: 0;
    width: 150px;
}

.benefit_graph .use_amount.zero {
    position: absolute;
    left: 0;
    top: 0;
}

.benefit_graph .use_amount.low {
    position: absolute;
    left: 80%;
    top: 0;
}

.benefit_graph .use_amount.top {
    position: absolute;
    left: calc(80% - 70px);
    top: 0;
}

.benefit_graph .bar_base .bar-fill.gray {
    background: #a8b3bd;
    width: 50%;
}

.benefit_graph .bar_base .bar-fill.gray.left-aligned {
    width: 0%;
}

.benefit_graph .bar_base .label.gray {
    border-color: #a8b3bd;
    margin-top: 0;
}

.benefit_graph .base_amount {
    position: absolute;
    left: calc(50% - 43px);
    bottom: 0;
}

.benefit_graph .base_amount.left-aligned {
    left: 0;
}

/* stepper */
.stepper {
    display: flex;
    border: 1px solid #e7ebee;
    border-radius: 4px;
    width: 126px;
    box-sizing: border-box;
}

.stepper .num {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e7ebee;
    border-left: 1px solid #e7ebee;
    box-sizing: border-box;
}

/* dot */
.dot_before {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #dce0e5;
}

.dot_after {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #66707a;
}

/* footer */
.footer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 24px 0 32px;
}

/* fixedBtn */
.fixedBtn {
    position: fixed;
    bottom: 0;
    width: 100%;
}

/* bar_DialongWaiting */
.bar_DialongWaiting {
    width: calc(100% - 20px);
    background-color: #dce0e5;
    height: 4px;
    border-radius: 999px;
    margin: 12px 0 18px;
    position: relative;
}

.bar_DialongWaiting::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    background-color: #e6007e;
    border-radius: 999px;
    width: 70%;
    height: 4px;
}

/* Marketing_calendar */
.Marketing_calendar .data {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 12px 20px 8px;
}

.Marketing_calendar .data span {
    padding: 0 7px;
    /* width: 18px; */
    width: 14.28%;
    text-align: center;
    justify-content: center;
}

.Marketing_calendar .data_num {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 8px 20px 12px;
}

.Marketing_calendar .data_num span {
    padding: 4px 5px;
    /* width: 18px; */
    width: 14.28%;
    height: 24px;
    color: #181a1b;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Marketing_calendar .data_num span.back {
    opacity: 0.3;
}

.Marketing_calendar .data_num span.attendance {
    color: rgba(24, 26, 27, 0.1);
    position: relative;
}

.Marketing_calendar .data_num span.attendance::before {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(../images/main/misson/attendance.svg) 0 0 no-repeat;
    position: absolute;
    /* left: 0;
    top: 0; */
}

.Marketing_calendar .data_num span.special_back {
    opacity: 0.3;
    position: relative;
}

.Marketing_calendar .data_num span.special_back::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #f86800;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px; /* 점을 조금 더 아래로 이동 */
}

.Marketing_calendar .data_num span.special {
    position: relative;
}

.Marketing_calendar .data_num span.special::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #f86800;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -6px; /* 점을 조금 더 아래로 이동 */
}

/* .Marketing_calendar .data_num span.d_day {
  background-color: #e6007e;
  border-radius: 9999px;
  color: #fff;
} */

.Marketing_calendar .data_num span.d_day span {
    /*background-color: #e6007e;*/
    /*border-radius: 50%;*/
    color: #fff;
    /*width: 20px;*/
    /*height: 20px;*/
}


.Marketing_calendar .data_num span.d_day {
    background-color: #e6007e;
    border-radius: 9999px;
    color: #fff;
    width: 32px; /* 고정된 너비 */
    height: 32px; /* 고정된 높이 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; /* 내부 여백 제거 */
    box-sizing: border-box;
}


.Marketing_calendar .data_list {
    display: flex;
    justify-content: flex-end;
    padding: 8px 20px 12px;
    gap: 16px;
}

.Marketing_calendar .data_list span {
    color: #66707a;
}

.Marketing_calendar .data_list > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.Marketing_calendar .data_list .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #e6007e;
}

.Marketing_calendar .data_list .dot.orange {
    background-color: #f86800;
}
