.slider-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slides-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.slide {
    flex: 0 0 100%;
    box-sizing: border-box;
}