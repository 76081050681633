.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1001;
}

.popup {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1002;
    width: calc(100% - 50px);
    text-align: center;
    margin-top: 10px; /* 바코드와의 거리 조정 */
    borderRadius: 20px
}

.barcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 50px);
    height: 163px;
    background: rgba(255, 255, 255, 255);
    border-radius: 10px;
    z-index: 999;
}