.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* 재은 삽입 CSS 디자인 시스템껄로 사용하게 바꿔야할걸요..? */

.app-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  overflow: hidden;
}

.main-content {
  flex: 1;
  /*overflow-y: auto;*/
}

.gnb {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.gnb a {
  text-decoration: none;
  color: #333;
}
