@import url(./style1.css);
@import url(./style2.css);

.gap-pt-12 {
    padding-top: 12px;
}

a {
    text-decoration: none;
}

/* dimmed */
.dimed_high {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
}

.dimed_low {
    background-color: #000;
    opacity: 0.42;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
}

/* styles.css */
@font-face {
    font-family: 'pretendard';
    src: url('./fonts/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

body {
    font-family: 'pretendard', sans-serif;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}